<script lang="ts" setup>
import { setLocale } from 'yup';
import type { LocaleObject } from 'vue-i18n-routing';
import type { NavItem } from '@/types/nav';
import { useTheme, useDisplay } from 'vuetify';

// Language switcher
const { locale, locales, t } = useI18n();
const switchLocalePath = useSwitchLocalePath();
const availableLocales = computed(() => {
    return (locales.value as LocaleObject[]).filter((i: LocaleObject) => i.code !== locale.value);
});

// Navigation
const localePath = useLocalePath();
const navigation = computed<NavItem[]>(() => {
    return [
        {
            name: 'Home',
            path: '/',
        },
        {
            name: 'Users',
            path: localePath('/demo/users'),
        },
        ...availableLocales.value.map((locale) => ({
            id: locale.code,
            name: locale.code.toUpperCase(),
            path: switchLocalePath(locale.code),
            className: 'ml-auto',
        })),
    ];
});

setLocale({
    mixed: {
        default: t('validation.default'),
        required: t('validation.required'),
    },
    string: {
        email: t('validation.email'),
    },
});

// The Display options (breakpoints, thresholds, etc) if needed
// const display = useDisplay();

// Use the theme api
const theme = useTheme();

function changeVuetifyTheme(themeName: string) {
    theme.global.name.value = themeName;
}
</script>

<template>
    <header class="bg-greys-800">
        <nav class="container mx-auto flex gap-6 px-2 py-4 text-sm">
            <NuxtLink
                v-for="nav in navigation"
                :key="nav.name"
                :to="nav.path"
                class="hover:underline"
                active-class="font-bold"
                :class="nav.className"
            >
                {{ nav.name }}
            </NuxtLink>
            <v-btn @click="changeVuetifyTheme('primaryTheme')" class="font-primary"> Primary </v-btn>
            <v-btn @click="changeVuetifyTheme('secondaryTheme')" class="font-primary"> Secondary </v-btn>
            <v-btn @click="changeVuetifyTheme('assemblyTheme')" class="font-primary"> Assembly </v-btn>
            <v-btn @click="changeVuetifyTheme('humanResourcesTheme')" class="font-primary"> Human Resources </v-btn>
            <v-btn @click="changeVuetifyTheme('marketStudyTheme')" class="font-primary"> Market Study </v-btn>
            <v-btn @click="changeVuetifyTheme('warehouseTheme')" class="font-primary"> Warehouse </v-btn>
        </nav>
    </header>
    <main class="container mx-auto bg-greys-800 px-2 py-4">
        <slot />
    </main>
</template>
